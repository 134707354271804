$size-sm: 375px;
$size-md: 768px;
$size-lg: 1024px;
$size-xl: 1100px;

$mob: $size-lg - 1px;

.desktop-only {
	@include until($mob) {
		display: none !important;
	}
}

.mobile-only {
	@include at($mob) {
		display: none !important;
	}
}

.row {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;

	&.no-margin {
		margin-bottom: 0;
	}

	@include at($mob) {
		margin-bottom: 3em;
		display: flex;
		flex-flow: row nowrap;
	}

	@include until($mob) {
		margin: 1em 0;
	}
}

.mobile-reorder {
	@include at($mob) {
		margin-top: inherit;
		margin-bottom: 2em;
		display: flex;
		flex-flow: row nowrap;
		$max: 10;
		@for $i from 1 through $max {
			> *:nth-child(#{$i}) {
				order: $max - $i;
			}
		}

		.section,
		.img-section {
			margin-bottom: 0;
		}

		.desktop-left {
			padding-left: 2em;
		}
	}
}

@function col($n) {
	@return 100% * ($n / 12);
}

@include at($mob) {
	@for $i from 1 through 12 {
		.col-#{$i} {
			width: col($i);
			flex-basis: col($i);
			margin: 0;
		}

		.offset-#{$i} {
			margin-left: col($i) !important;
		}
	}
}
