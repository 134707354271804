// picked
$color-white: #fff;
$color-black: #000;

$color-pink: #A02644;
$color-yellow: #EABB6F;
$color-brand: $color-pink;

// $color-gray-medium: #bfbfbd;
$color-gray-light: #EFEFEF;
$color-gray-lighter: #F7F7F7;
$color-gray-dark: #5B5B5B;



// default
// $color-gray-darker: #222;
// $color-gray-dark: #575756;
// $color-warning: #b8533e;
$color-text: $color-gray-dark;

// $color-focus: #99e878;