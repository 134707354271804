body {
	display: flex;
	align-items: center;
	flex-flow: column nowrap;
}

.main {
	min-width: 320px;
	width: 100%;
	max-width: $size-xl;
	background-color: $color-white;
	min-height: 100vh;

	@include at($mob) {
		.row {
			margin-bottom: 4em;

			&.no-margin {
				margin-bottom: 0;
			}
		}
	}
}
