@font-face {
	font-family: "Lato";
	src: url("../fonts/LatoLatin-Regular.eot");
	src: local("☺"),
		url("../fonts/LatoLatin-Regular.eot?#iefix") format("embedded-opentype"),
		url("../fonts/LatoLatin-Regular.woff2") format("woff2"),
		url("../fonts/LatoLatin-Regular.woff") format("woff"),
		url("../fonts/LatoLatin-Regular.ttf") format("truetype");
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: "Lato";
	src: url("../fonts/LatoLatin-Bold.eot");
	src: local("☺"),
		url("../fonts/LatoLatin-Bold.eot?#iefix")
			format("embedded-opentype"),
		url("../fonts/LatoLatin-Bold.woff2") format("woff2"),
		url("../fonts/LatoLatin-Bold.woff") format("woff"),
		url("../fonts/LatoLatin-Bold.ttf") format("truetype");
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: "Trajan";
	src: url("../fonts/trajanpro-regular-webfont.eot");
	src: local("☺"),
		url("../fonts/trajanpro-regular-webfont.woff") format("woff"),
		url("../fonts/trajanpro-regular-webfont.ttf") format("truetype"),
		url("../fonts/trajanpro-regular-webfont.svg#webfontEuQNCEHJ")
			format("svg");
	font-weight: normal;
	font-style: normal;
}
