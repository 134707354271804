@mixin at($mq) {
	@media(min-width: $mq) {
		@content;
	}
}
@mixin until($mq) {
	@media(max-width: $mq - 1px) {
		@content;
	}
}

@mixin body-font() {
	font-family: $body-font;
	font-weight: normal;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-smoothing: grayscale;
}

@mixin head-font() {
	font-family: $head-font;
	font-weight: normal;
	font-style: normal;
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
	-moz-font-smoothing: auto;
}

@mixin font-size($font-size: 12) {
	font-size: $font-size + px; //fallback for old browsers
	font-size: (0.0625 * $font-size) + rem;
}

@mixin line-height($height-px) {
	line-height: $height-px + px; //fallback for old browsers
	line-height: (0.0625 * $height-px) + rem;
}

$line-height-default: 1.5;
@mixin typo($size-px: 12, $line-height-px: false, $line-height-factor: $line-height-default) {
	@include font-size($size-px);

	@if $line-height-px {
		@include line-height($line-height-px);
	} @else {
		@include line-height($line-height-factor * $size-px);
	}
}
