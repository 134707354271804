html[data-useragent*="iPhone"],
html[data-useragent*="Safari"],
html[data-useragent*="rv:11"],
html[data-useragent*="MSIE 10.0"] {
}

html[data-useragent*="MSIE 10.0"],
html[data-useragent*="rv:11"] {
	.text-on-image {
		@include at($mob) {
			height: 600px;
			flex-basis: 600px;
			max-height: none;
		}
	}

	.section.intro {
		.intro-bg {
			height: 600px;
		}
	}
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main,
fieldset,
legend {
	display: block;
}
