.section {
	padding: 1em;
	position: relative;

	display: flex;
	flex-flow: column nowrap;

	text-align: center;

	@include at($mob) {
		padding: 3em 1em;
		justify-content: center;
	}

	.section-top-img {
		margin-bottom: 3em;
	}
	.section-bottom-img {
		margin: 2em 0;
	}

	h1,
	h2,
	h3,
	h4 {
		&:first-child {
			margin-top: 0;
		}
	}

	&.gray {
		margin: 0 1em;
		background-color: $color-gray-light;

		@include at($mob) {
			margin: 0;
		}
		@include until($mob) {
			padding: 2em 1em;
		}
	}

	&.desktop-left {
		@include at($mob) {
			text-align: left;
		}
	}

	&.mobile-padding {
		@include until($mob) {
			padding: 2em 1em 3em 1em;
		}
	}

	&.inner-padding {
		@include at($size-sm) {
			padding-left: 6em;
			padding-right: 6em;
		}
		@include at($size-md) {
			padding-left: 2em;
			padding-right: 2em;
		}
		@include at($size-lg) {
			padding-left: 3em;
			padding-right: 3em;
		}
		@include at($size-xl) {
			padding-left: 6em;
			padding-right: 6em;
		}
	}

	&.inner-padding.col-5 {
		@include at($size-sm) {
			padding-left: 6em;
			padding-right: 6em;
		}
		@include at($size-md) {
			padding-left: 2em;
			padding-right: 2em;
		}
		@include at($size-lg) {
			padding-left: 4em;
			padding-right: 4em;
		}
	}

	&.full {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;

		@include at($mob) {
			margin-top: 2em;
			margin-bottom: 2em;
		}

		img {
			margin: 0 1em;

			&.full {
				margin: 0;
			}
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		p {
			padding: 0 1em;
			@include at($mob) {
				padding: 0 2em 0 0;
			}

			&.full {
				padding: 0;
			}
		}

		&.gray {
			padding: 2em 1em;
		}
	}

	.section-inner {
		display: block;
		flex: 1 1 auto;
	}
}

.spacer {
	min-height: 4em;
	@include at($mob) {
		display: none;
	}
}

.img-section {
	padding: 1em 0;

	@include at($mob) {
		margin-bottom: 0;
		padding: 0;
	}

	@include until($mob) {
		margin: 1em 0 0 0;
	}

	&.full {
		padding: 0;
	}
	img {
		object-fit: cover;
		font-family: "object-fit: cover;";

		@include until($mob) {
			min-height: 280px;
		}
		@include at($mob) {
			display: block;
			width: 100%;
			height: 100%;
		}
	}
}

.section.intro {
	position: relative;
	padding: 0;
	margin-top: 0;

	@include at($mob) {
		position: static;
		flex-basis: 100%;
		width: 100%;
		margin-bottom: 0;
	}

	.intro-bg {
		margin: 0;
		width: 100%;
		height: auto;
		min-height: 160px;
		height: 50vw;
		max-height: 600px;
	}

	.intro-title {
		color: $color-white;
		background-color: $color-yellow;
		text-align: center;
		z-index: 1;
		padding: 0.75em 1em;
		margin: 0;

		@include at($mob) {
			width: col(7);
			text-align: left;
			height: 64px;
			line-height: 32px;
			margin-top: -64px;
		}
	}
}

.text-on-image {
	position: relative;
	margin-top: 4em;

	&-text {
		position: absolute;
		top: 40%;
		left: 0;
		color: $color-white;
		transform: translateY(-50%);

		p {
			padding-right: 2em;
		}
	}

	&-image {
		width: 100%;
		height: auto;
		object-fit: cover;
		font-family: "object-fit: cover;";
	}

	@include at($mob) {
		max-height: 600px;
	}
}

.personality {
	@include until($mob) {
		h1,
		h2,
		h3,
		h4,
		h5 {
			margin: 0.5em;
		}
	}

	@include at($mob) {
		padding: 0;
		background-color: $color-brand;
		color: $color-white;
	}
}

.section-gmaps {
	position: relative;
	padding-bottom: 0;

	[data-gmaps] {
		background-color: $color-gray-dark;
		width: 100%;
		height: 250px;

		@include at($mob) {
			height: 500px;
		}
	}

	&-link {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;

		background-color: $color-brand;
		color: $color-white;
		padding: 0.3em 1em;
		text-decoration: none;
		font-weight: bold;
		text-transform: uppercase;
		font-size: 0.66em;
		transition: background-color 100ms;

		&:hover {
			background-color: $color-white;
		}
	}
}
