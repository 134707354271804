.header {
	position: relative;
	width: 100%;
	min-height: 120px;
	margin-bottom: 0;

	@include at($mob) {
		min-height: 180px;
	}

	svg {
		display: block;
		width: auto;
		height: 110px;

		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&-link {
		display: block;
		width: auto;
		min-height: 44px;
		min-width: 44px;
		line-height: 44px;

		position: absolute;
		top: 50%;
		right: 1em;
		transform: translateY(-50%);

		@include at($size-sm) {
			right: 1.5em;
		}

		color: $color-brand;
		text-transform: uppercase;
	
	}

	&-contact {
		color: $color-brand;
		width: 33.333%;
		position: absolute;
		left: 66.66%;
		top: 50%;
		transform: translateY(-50%);

		a {
			display: inline-block;
			text-decoration: none;
			margin-bottom: 0.75em;
		}
	}
}
