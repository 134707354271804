*, *:before, *:after {
	box-sizing: border-box;
}

body {
	background: $color-gray-lighter;
	color: $color-text;
	letter-spacing: 0.05em;
	@include body-font;
	font-size: 16px;
	line-height: 22px;

	@include at($mob) {
		@include typo(17, 23);
	}

}

h1 {
	@include typo(20, 25);
	@include body-font;
	font-weight: bold;
	text-transform: uppercase;
}

h2 {
	@include head-font;
	@include typo(23, 29);
	text-transform: uppercase;
}

h3 {
	@include typo(20, 25);
	text-transform: uppercase;
}

h4,
h5 {
	text-transform: uppercase;
}

b,
strong {
	font-weight: bold;
}

i,
em {
	font-style: italic;
}

a {
	color: currentColor;
	text-decoration: underline;
	&:hover {
		color: $color-brand;
	}
}

p {
	margin-top: 0;
	margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}
}

sub {
	font-size: 0.7em;
	position: relative;
	bottom: -0.3em;
}

