.impressum {

	&-h1 {
		margin-bottom: 0.5em;
		font-size: 1em;
		color: #fff;
		margin-bottom: 0.5em;
	}

	.header-link {
		top: 50%;
		right: auto;
		left: 1em;

		@include at($mob) {
			left: col(1);
			padding-left: 1em;
		}
	}

	.section {
		text-align: left;

		@include at($mob) {
			justify-content: flex-start;
		}
	}
	p,
	a,
	h2,
	h3,
	h4 {
		hyphens: auto;
	}

	&-header {
		background-color: $color-yellow;

		p,
		a,
		h2,
		h3,
		h4 {
			color: $color-white;
		}
	}

	.subfooter {
		text-align: center;
	}
}
