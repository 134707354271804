.ornament {
	padding: 2em 0 1em 0;
	text-align: center;

	svg {
		display: inline-block;
		height: 4em;
		width: auto;

		path {
			fill: currentColor;
		}
	}

}

